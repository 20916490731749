<template>
    <div class="wrap">
        <my-header></my-header>
        <div class="section">
            <div class="banner">
                <transition name="slide-fade">
                    <div v-if="show">
                        <p>{{ $t('Public.SecurityService.banner.title') }}</p>
                        <div class="banner-txt">{{ $t('Public.SecurityService.banner.text') }}</div>
                    </div>
                </transition>
            </div>
            <div class="content">
                <div class="big_data">
                    <p class="detail_title">{{ $t('Public.SecurityService.security.title') }}</p>
                </div>
                <div class="security_text">
                    {{ $t('Public.SecurityService.security.text') }}
                </div>
                <ul class="txt_content">
                    <li v-for="(item, index) in txtArr" :key="index">
                        <div :class="'icon' + index"></div>
                        {{ $t('Public.SecurityService.security.content[' + index + '].text') }}
                    </li>
                </ul>
            </div>
        </div>
        <my-footer></my-footer>
    </div>
</template>

<script>
// @ is an alias to /src
import MyHeader from '@/components/MyHeader.vue';
import MyFooter from '@/components/MyFooter.vue';

export default {
    name: 'SecurityService',
    components: {
        MyHeader,
        MyFooter
    },
    data() {
        return {
            show: false,
            txtArr: [{}, {}, {}]
        };
    },
    methods: {},
    mounted() {
        this.show = true;
    }
};
</script>
<style scoped>
.banner {
    width: 100%;
    height: 5.76rem;
    padding: 1.8rem 3rem 0 3.6rem;
    font-size: 0.22rem;
    font-weight: 300;
    background: url(../../public/images/service/security/banner.jpg) no-repeat;
    background-size: 100% 100%;
    line-height: 2;
    background-color: #31575c;
}
.banner p {
    font-size: 0.54rem;
    line-height: 1.39;
    margin-bottom: 0.28rem;
}
.banner-txt {
    text-align: justify;
}
.content {
    padding: 1rem 3.6rem 0.93rem;
    color: #333;
}
.security_text {
    line-height: 2;
    font-weight: 400;
    font-size: 0.2rem;
    margin-top: 0.6rem;
    text-align: justify;
}
.detail_title {
    font-size: 0.32rem;
    font-weight: 600;
    text-align: center;
}
.txt_content {
    margin-left: 0.8rem;
    margin-top: 0.3rem;
}
.txt_content li {
    margin-bottom: 0.2rem;
    font-size: 0.2rem;
    position: relative;
    margin-bottom: 0.3rem;
}
.txt_content li div {
    width: 0.3rem;
    height: 0.3rem;
    display: inline-block;
    position: absolute;
    left: -0.4rem;
    top: 50%;
    transform: translateY(-50%);
}
.icon0 {
    background: url(../../public/images/service/peisong.png) no-repeat;
    background-size: cover;
}
.icon1 {
    background: url(../../public/images/service/wuliu.png) no-repeat;
    background-size: cover;
}
.icon2 {
    background: url(../../public/images/service/fengxian.png) no-repeat;
    background-size: cover;
}
</style>
